





































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { MessageBoxInputData } from "element-ui/types/message-box";
import CompleteOrderDialog from "./components/CompleteOrderDialog.vue";
import OrderRefundDialog from "./components/OrderRefundDialog.vue";
import OrderUp from './components/OrderUp.vue'
 import { Route, RawLocation } from 'vue-router';



@Component({
  components: {
    CompleteOrderDialog,
    OrderRefundDialog,
    OrderUp
  }
})
// @ts-ignore
export default class OrderList extends Vue {
  pagerHeader: any = {
    title: "租赁订单",
    desc:
      "若要查看超时订单，请分别选择订单状态为【已完成】和归还状态为【未归还】组合查询",
    breadcrumb: ["订单管理", "租赁订单"]
  };
  loading: boolean = false;
  filter: any = {
    page: 1,
    size: 10,
    keywords: "",
    startDate: null,
    endDate: null,
    status: [],
    merchantId: "",
    paymentType: "",
    agentId: "",
    isStaffOrder:null,
    isReturned: '',
    memberId: null,
    sortField: null,
    sortDirection: null
  };
  dateRange: any = [];
  tableData: any = {
    items: [],
    totalCount: 0,
    totalAmount: 0
  };
  merchantItems: any[] = [];
  merchantSearchLoading: boolean = false;

  // 分页开始
  handleSizeChange(val: number) {
    this.filter.size = val;
    this.readerPage();
  }
  handleCurrentChange(val: number) {
      console.log(val);
      
    this.filter.page = val;
    this.readerPage();
  }
//   beforeRouteEnter(to:any,from:any,next:any){
//       console.log(to.query);
      
//       next()
//   }
    //日期改变   
  timeVoi(value:Date[]){
    if (value != null && value.length == 2) {
        this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss")
        if(value[1].Format('hh')=="00"&&value[1].Format('mm')=="00"&&value[1].Format('ss')=="00"){
            this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59");
            this.dateRange=[this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss"),this.filter.endDate = value[1].Format("yyyy-MM-dd 23:59:59")]
        }else{
            this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss")
            this.dateRange=[this.filter.startDate=value[0].Format("yyyy-MM-dd hh:mm:ss"),this.filter.endDate = value[1].Format("yyyy-MM-dd hh:mm:ss")]
        }
      this.readerPage()
    } else {
      this.filter.startDate = "";
      this.filter.endDate = "";
      this.readerPage()
    } 
  }
  async readerPage() {
    this.loading = true;
    try {
      let res = await this.$ajax.post(
        "/api/services/app/ChargingOrder/QueryPagedOrders",
      {
            page: this.filter.page,
            size: this.filter.size,
            keywords: this.filter.keywords,
            startDate:  this.filter.startDate,
            endDate:  this.filter.endDate,
            status: this.filter.status,
            merchantId: this.filter.merchantId,
            paymentType: this.filter.paymentType===""?null:this.filter.paymentType,
            agentId: this.filter.agentId,
            isStaffOrder:this.filter.isStaffOrder,
            isReturned: this.filter.isReturned===""?null:this.filter.isReturned,
            memberId: this.filter.memberId,
            sortField: this.filter.sortField,
            sortDirection: this.filter.sortDirection
      }
      );

      if (res.data.success) {
        this.tableData = res.data.result;
      }
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  search() {
    this.filter.page = 1;
    // this.filter.keywords = this.keywords;
    this.readerPage();
  }
//   只有keep-alive 存在时 才会每次都触发
  activated() {
    this.readerPage();
  }

  
//   只有创建的时候才会触发一次，
  created() {
    if (this.$route.query.memberId) {
      this.filter.memberId = this.$route.query.memberId;
      this.readerMemberOptions(this.filter.memberId);
    }else if(this.$route.query.paymentType){
        this.filter.paymentType = Number(this.$route.query.paymentType) ;
    }
        if(this.$route.query.status||this.$route.query.status==='0'){
        this.filter.status.push(Number(this.$route.query.status))
    }
    // 根据参数知道是否需要根据时间搜索 从统计点击过来
    if(this.$route.query.time=="0"){
        this.getTime(0)
    }else if(this.$route.query.time==="1"){
        this.getTime(1)
    }else if(this.$route.query.time==="2"){
        this.getTime(7)
    }else if(this.$route.query.time==="3"){
        this.getTime(30)
    }
    if(this.$route.query.isStaffOrder=='1'){
        this.filter.isStaffOrder=true
    }
    if(this.$route.query.keywords){
        this.filter.keywords=this.$route.query.keywords
    }
    this.filter.size = this.systemSettings.pageSize;
    this.readerPage();
    this.readerMerchantOptions("");
    this.readerAgentOptions("");
  }

// 处理传过来的时间数字
    getTime(days:any){
        if(days==1){
           // @ts-ignore
            this.filter.startDate =new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00")
            // @ts-ignore
            this.filter.endDate =new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 23:59:59")
           // @ts-ignore
            this.dateRange=[new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00"),new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 23:59:59")]
        }else{
            // @ts-ignore
            this.filter.startDate =new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00")
            this.filter.endDate =new Date().Format("yyyy-MM-dd 23:59:59")
            // @ts-ignore
            this.dateRange=[new Date(new Date()-days*24*3600*1000).Format("yyyy-MM-dd 00:00:00"),new Date().Format("yyyy-MM-dd 23:59:59")]
        }
            
    }

  get paymentTypeOptions() {
    return [
      {
        name: "微信",
        value: 8
      },
      {
        name: "支付宝",
        value: 16
      }
    ];
  }
   get statusOptions() {
    return  this.getEnum("ChargingOrderStatus");
  }
  async readerMerchantOptions(query: string) {
    this.merchantSearchLoading = true;
    try {
      var res = await this.$ajax.get(
        "/api/services/app/Merchant/GetPagedList",
        {
          params: {
            page: 1,
            size: 10,
            keywords: query
          }
        }
      );

      this.merchantItems = res.data.result.items;
    } catch (error) {}
    this.merchantSearchLoading = false;
  }
  memberSearchLoading: boolean = false;
  memberItems: any[] = [];
  async readerMemberOptions(query: string) {
    this.memberSearchLoading = true;
    try {
      var res = await this.$ajax.post(
        "/api/services/app/Member/QueryPagedList",
        {
          page: 1,
          size: 10,
          keywords: query
        }
      );
      this.memberItems = res.data.result.items;
    } catch (error) {}
    this.memberSearchLoading = false;
  }
  async cancelOrder(id: string) {
    var dialogResult = (await this.$prompt(
      "请输入取消原因",
      "提示"
    )) as MessageBoxInputData;
    if (dialogResult.action == "confirm") {
      if (!dialogResult.value) return this.$notify.error("请输入取消原因");
      let loading = this.$loading({
        text: "取消中..."
      });
      try {
        if (dialogResult && dialogResult.action == "confirm") {
          var res = await this.$ajax.post(
            `/api/services/app/ChargingOrder/CancelOrder`,
            {
              id: id,
              reason: dialogResult.value
            }
          );
          if (res.data.success) {
            this.$message.success("取消成功");
            this.readerPage();
          }
        }
      } catch (error) {}
      loading.close();
    }
  }
  agentItems: any[] = [];
  agentSearchLoading: boolean = false;
  async readerAgentOptions(query: string) {
    this.agentSearchLoading = true;
    try {
      var res = await this.$ajax.get("/api/services/app/Agent/GetPagedList", {
        params: {
          page: 1,
          size: 10,
          keywords: query
        }
      });
      this.agentItems = res.data.result.items;
    } catch (error) {}
    this.agentSearchLoading = false;
  }
  completeOrder(row: any) {
    (this.$refs.completeOrderDialog as any).show(row);
  }
  // 退款
  orderRefund(id: number) {
    (this.$refs.orderRefundDialog as any).show(id);
  }
  // 上报
  orderUp(orderNo:number,paymentStatus:number){
    (this.$refs.orderUpDialog as any).show(orderNo,paymentStatus);
  }


  async sortChange(e: any) {
    console.log(e);
    if (e.order) {
      this.filter.sortField = e.prop;
      if (e.order == "ascending") {
        this.filter.sortDirection = 0;
      } else {
        this.filter.sortDirection = 1;
      }
    } else {
      this.filter.sortDirection = null;
      this.filter.sortField = null;
    }
    this.readerPage();
  }

}
