

















import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class OrderRefundDialog extends Vue {
  dialogFormVisible: boolean = false;
  formData: any = {
    amount: "",
    reason: "",
    id: ""
  };
  loading: boolean = false;
  rules: any = {
    amount: [{ required: true, message: "选择输入退款金额", trigger: "blur" }],
    reason: [{ required: true, message: "请输入原因", trigger: "blur" }]
  };
  show(id: string) {
    this.dialogFormVisible = true;
    this.formData = {
      amount: "",
      reason: "",
      id
    };
  }
  submitAudit() {
    (this.$refs["auditForm"] as any).validate(async (valid: boolean) => {
      if (valid) {
        this.loading = true;
        try {
          var res = await this.$ajax.post(
            "/api/services/app/ChargingOrder/OrderRefund",
            this.formData
          );
          if (res.data.success) {
            this.dialogFormVisible = false;
            this.$notify.success("退款成功");
            this.$emit("confirm");
          }
        } catch (error) {}
      }
      this.loading = false;
    });
  }
}
