














































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class CompleteOrderDialog extends Vue {
  dialogFormVisible: boolean = false;
  formData: any = {
    deductioned: false,
    tradeNo: "",
    refundNo: "",
    returnTime: "",
    id: ""
  };
  orderItem: any = null;
  loading: boolean = false;
  rules: any = {
    tradeNo: [{ required: true, message: "请输入交易号", trigger: "blur" }],
    refundNo: [{ required: true, message: "请输入退款号", trigger: "blur" }],
    returnTime: [{ required: true, message: "请输入归还时间", trigger: "blur" }]
  };
  show(orderItem: any) {
    this.dialogFormVisible = true;
    this.orderItem = orderItem;
    this.formData = {
      deductioned: false,
      tradeNo: "",
      refundNo: "",
      returnTime: "",
      id: orderItem.id
    };
  }
  submitDeliver() {
    
    (this.$refs["completeForm"] as any).validate(async (valid: boolean) => {
      if (valid) {
        this.loading = true;
        console.log(this.formData)
        try {
          var res = await this.$ajax.post(
            "/api/services/app/ChargingOrder/CompleteOrder",
            this.formData
          );
          if (res.data.success) {
            this.dialogFormVisible = false;
            this.$notify.success("完成成功");
            this.$emit("confirm");
          }
        } catch (error) {}
      }
      this.loading = false;
    });
  }
}
