






















import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class OrderRefundDialog extends Vue {
  dialogFormVisible: boolean = false;
  formData: any = {
    alipayOrderBizInfo: "0",
    orderNo: ""
  };
  orderStatus:any=[
      {label:'守约',value:"0",disabled:false},
      {label:'违约',value:"-2",disabled:false},
      {label:'关闭',value:"-1",disabled:false}
  ];
  loading: boolean = false;
  rules: any = {
    alipayOrderBizInfo: [{ required: true, message: "请选择", trigger: "blur" }],
  };
  show(orderNo: string,paymentStatus:number) {
      let paymentStatus1=''
      if(paymentStatus==2){ //已支付
        this.orderStatus[1].disabled=true
        paymentStatus1='0'
      }else if(paymentStatus==0){ //未支付
          this.orderStatus[0].disabled=true
        paymentStatus1='-2'
      }
    this.dialogFormVisible = true;
    this.formData = {
      alipayOrderBizInfo: paymentStatus1,
      orderNo
    };
  }
  submitAudit() {
    (this.$refs["auditForm"] as any).validate(async (valid: boolean) => {
      if (valid) {
        this.loading = true;
        try {
          var res = await this.$ajax.post(
            "/api/Alipay/CreditReport?orderNo="+this.formData.orderNo+'&alipayOrderBizInfo='+this.formData.alipayOrderBizInfo,
            // this.formData
          );
          if (res.data.success) {
            this.dialogFormVisible = false;
            this.$notify.success("上报成功");
            this.$emit("confirm");
          }
        } catch (error) {}
      }
      this.loading = false;
    });
  }
}
